import {DefaultService} from "@/services/default";


export class BookingService extends DefaultService {
    static resourceURL = 'booking/'

    static batchAssign(data, params = {}, headers = {}) {
        return this.request('batch-assign/', 'PATCH', data, params, headers);
    }

    static operationBookings(id, params = {}, headers = {}) {
        return this.request(`operation/${id}/`, 'GET', null, params);
    }

    static toggleOpen(id, params = {}, headers = {}) {
        return this.request(`${id}/toggle-open/`, 'GET', null, params, headers);
    }

    static reassignUnit(id, data, params = {}, headers = {}) {
        return this.request(`${id}/reassign-unit/`, 'POST', data, params, headers);
    }

}