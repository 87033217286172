<template>
  <Change v-model:loading="loading" :params="params" :title="title" change-view="operationBookingsView">
    <template v-slot:navigation>
      <div/>
    </template>
    <template v-slot:title>
      <span class="fs-3 text-dark fw-bold">Operations</span>
    </template>
    <template v-slot:subtitle>
      <form class="row gx-2" @submit.prevent="searchOrderLine">
        <div class="col-auto">
          <input v-model="searchInput" class="form-control form-control-sm"
                 name="sku" placeholder="Unit SKU | Order Number | Booking ID" type="text">
        </div>
        <div class="col-auto">
          <input class="btn btn-sm btn-outline-dark search-bar " type="submit" value="Add">
        </div>
        <div class="col-auto">
          <button class="btn btn-sm btn-outline-dark search-bar" type="button" @click="clearOrderLines">Remove all
          </button>
        </div>
      </form>
    </template>
    <template v-slot:manager>
      <Table v-model:elements="orderLinesFound" :table-total="orderLinesFound.length" :tableHeaders="tableHeaders"
             no-delete title="Booking" @clearSelection="clearSelection">
        <template v-slot:before-actions>
          <div class="col-auto hstack gap-2">
            <label>Status: </label>
            <select v-model="selectedStatus" class="form-select form-select-sm">
              <option value="">---------</option>
              <option v-for="status in statuses" :key="status.id" :value="status.name">
                {{ status.name }}
              </option>
            </select>
          </div><!-- Change Status -->
          <div class="col-auto hstack gap-2">
            <label>Location:</label>
            <select v-model="selectedLocation" class="form-select form-select-sm">
              <option value="">---------</option>
              <option v-for="location in locations" :key="location.id" :value="location.name">
                {{ location.name }}
              </option>
            </select>
          </div><!-- Change Location -->
          <div class="col-auto hstack gap-2">
            <label class="text-nowrap">Order Status:</label>
            <select v-model="selectedOrderStatus" class="form-select form-select-sm">
              <option value="">---------</option>
              <option v-for="order_status in orderStatuses" :key="order_status.id" :value="order_status.name">
                {{ order_status.name }}
              </option>
            </select>
          </div><!-- Change Order Status -->
          <div class="col-auto">
            <button class="btn btn-sm btn-outline-dark search-bar" @click.prevent="openChangeModal()">Change</button>
          </div><!-- Change Button -->
          <div class="col-auto">
            <button class="btn btn-sm btn-outline-warning search-bar" @click.prevent="removeOrderLines">Remove</button>
          </div><!-- Remove Button -->
        </template>
        <template v-slot:table-rows>
          <tr v-for="orderLine of orderLinesFound" :class="{'table-primary':orderLine.checked}">
            <th class="action-checkbox-column" scope="row">
              <input :id="orderLine.id" v-model="orderLine.checked" name="action-select" type="checkbox">
            </th> <!-- Action checkbox column -->
            <td>
              <ImgLink :pk="orderLine.id" :text="orderLine.id?.toString().padStart(4, '0')" name="BookingUpdate"/>
            </td><!-- Booking -->
            <td>
              <div class="row">
                <div v-for="operation of orderLine.operations" class="col-12">{{ operation }}</div>
              </div>
            </td><!-- Operation -->
            <td :class="{'table-danger':!orderLine.order}">
              <template v-if="orderLine.order">
                <ImgLink :channel_id="orderLine.order_channel_id" :pk="orderLine.order"
                         :text="orderLine.order_number?.toString()" name="OrderUpdate"/>
                <span v-if="orderLine.order_bookings > 1"> (<router-link
                    :to="{ name: 'BookingList', query: { order: orderLine.order_number } }" target="_blank">
                    {{ orderLine.order_bookings }}
                  </router-link>)
                </span>
              </template>
            </td><!-- Order -->
            <td :class="{'table-danger':!orderLine.unit}">
              <template v-if="orderLine.unit">
                <ImgLink :channel_id="orderLine.product_channel_id" :image="orderLine.image" :pk="orderLine.unit?.id"
                         :text="orderLine.unit?.sku" name="UnitUpdate"/>
                <span v-if="orderLine.variation_units > 1"> (<router-link
                    :to="{ name: 'UnitList', query: { sku: orderLine.variation_sku } }" target="_blank">
                    {{ orderLine.variation_units }}
                  </router-link>)
                </span>
              </template>
            </td><!-- Unit -->
            <td :class="{'table-danger':!orderLine.unit}">
              <select v-if="orderLine.unit" class="btn btn-sm btn-outline-dark dropdown-toggle"
                      @change="getSelectedElements([orderLine], $event.target, null,null)">
                <template v-if="statuses.length">
                  <option v-for="status in statuses" :key="status.id" :selected="orderLine.unit?.status.id===status.id"
                          :value="status.name">
                    {{ status.name }}
                  </option>
                </template>
                <option v-else>{{ orderLine.unit?.status.name }}</option>
              </select>
            </td><!-- Unit Status -->
            <td :class="{'table-danger':!orderLine.unit}">
              <select v-if="orderLine.unit" class="btn btn-sm btn-outline-dark dropdown-toggle"
                      @change="getSelectedElements([orderLine], null, $event.target,null)">
                <template v-if="locations.length">
                  <option v-for="location in locations" :key="location.id"
                          :selected="orderLine.unit?.location.id===location.id"
                          :value="location.name">
                    {{ location.name }}
                  </option>
                </template>
                <option v-else>{{ orderLine.unit?.location.name }}</option>
              </select>
            </td><!-- Location -->
            <td :class="{'table-danger':!orderLine.unit}">
              <div>{{ orderLine.unit?.storage.name }}</div>
            </td><!-- Storage -->
            <td :class="{'table-danger':orderLine.is_cancel}">
              <div>
                <a :href="`${appStore.wcUrl}/producto/${orderLine.product_channel_url??''}`" class="bi bi-globe pe-2"
                   target="_blank">
                  <span v-tooltip="orderLine.name" class="text-truncate ps-2" style="max-width: 174px">
                    {{ orderLine.name ?? "" }}
                  </span>
                </a>
              </div>
            </td><!-- Name -->
            <td>
              <div>{{ orderLine.brand ?? "" }}</div>
            </td><!-- Brand -->
            <td :class="{'table-danger':!orderLine.order}">
              <div>{{ orderLine.client ?? "" }}</div>
            </td><!-- Client -->
            <td>
              <DateDisplay v-if="orderLine.type==='rent'" :end="orderLine.rent_end" :start="orderLine.rent_start"/>
              <DateDisplay v-if="orderLine.type==='sale'" :start="orderLine.fulfillment_date"/>
            </td><!-- Dates-->
            <td>
              <div v-tooltip="orderLine.order_note" class="text-truncate">{{ orderLine.order_note }}</div>
            </td><!-- Note -->
            <td>
              <div>
                <span v-if="orderLine.type==='rent'" class="text-bg-success badge rounded-pill">Alquiler</span>
                <span v-if="orderLine.type==='sale'" class="text-bg-warning badge rounded-pill">Venta</span>
              </div>
            </td><!-- Rent/Sale -->
            <td>
              <DateDisplay :end="orderLine.unit?.next_booking?.rent_end"
                           :start="orderLine.unit?.next_booking?.rent_start"/>
            </td><!-- Next Booking-->
            <td>
              <div v-if="orderLine.is_return" class="text-center">
                <i class="bi bi-x-circle-fill text-danger"></i>
              </div>
            </td><!-- Returned -->
            <td>
              <DateDisplay :end="orderLine.blocking_end" :start="orderLine.blocking_start"/>
            </td><!-- Blocking  -->
            <td>
              <DateDisplay :end="orderLine.shipping_end" :start="orderLine.shipping_start"/>
            </td><!-- Shipping -->
            <td :class="{'table-danger':['Cancelado','Reserva Cancelada'].includes(orderLine.order_status)}">
              <select v-if="orderLine.order" class="btn btn-sm btn-outline-dark dropdown-toggle"
                      @change="getSelectedElements([orderLine], null, null, $event.target)">
                <template v-if="orderStatuses.length">
                  <option v-for="orderStatus in orderStatuses" :key="orderStatus.id"
                          :selected="orderLine.order_status.id===orderStatus.id"
                          :value="orderStatus.name">
                    {{ orderStatus.name }}
                  </option>
                </template>
                <option v-else>{{ orderLine.order_status?.name }}</option>
              </select>
            </td><!-- Order Status-->
            <td :class="{'table-danger':!orderLine.order}">
              {{ orderTypes.find(e => e.id === orderLine.order_type)?.name ?? orderLine.arrival_type }}
            </td><!-- Order Type -->
            <td :class="{'table-danger':!orderLine.order}">
              {{ deliveryTypes.find(e => e.id === orderLine.arrival_type)?.name ?? orderLine.arrival_type }}
            </td><!-- Shipping Type -->
            <td>
              <div>{{ bookingStatuses.find(e => e.id === orderLine.status)?.name ?? orderLine.status }}</div>
              <!--              <div>{{ booking.status }}</div>-->
            </td><!-- Booking Status-->
            <td>
              <div>{{ orderLine.unit_remaining_uses ?? "" }}</div>
            </td><!-- Remaining Uses -->
            <td>
              <DateDisplay :start="orderLine.created"/>
            </td><!-- Created -->
            <td>
              <DateDisplay :start="orderLine.updated"/>
            </td><!-- Updated -->
          </tr>
        </template>
      </Table> <!-- Table -->
    </template>
    <template v-slot:preview>
      <Preview v-if="orderLinesFound.length" :element="[orderLinesFound]" :reload="reloadOrderLines"/>
    </template>
  </Change>
  <ModalPopup v-model="changeModalElement" :title="changeModalData?.title" extra-class="modal-xl"
              modal-id="change-modal">
    <template v-if="changeModalData" v-slot:body>
      <div>
        <div class="d-flex justify-content-between">
          <p class="fs-5" v-html="changeModalData.subtitle"/>
        </div>
        <table v-if="changeModalData.data.length" class="table table-bordered table-hover table-sm text-nowrap">
          <thead class="table-secondary">
          <tr>
            <th v-if="changeModalData.previousElements.data.length || changeModalData.closedElements.data.length"
                scope="col">Check
            </th>
            <th>Order</th>
            <th>Unit</th>
            <th>Unit Status</th>
            <th>Location</th>
            <th>Name</th>
            <th>Client</th>
            <th>Order Status</th>
            <th>Dates</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="element of changeModalData.data"
              :class="{'table-active':element.checked, 'table-danger':element.closed}">
            <th v-if="changeModalData.previousElements.data.length || changeModalData.closedElements.data.length"
                class="action-checkbox-column">
              <input :id="element.slug" v-model="element.checked" name="action-select" type="checkbox"
                     @input="togglePrevUnit(element)">
            </th> <!-- Action checkbox column -->
            <td>{{ element.order }}</td>
            <td>{{ element.sku }}</td>
            <td>{{ element.last_status }}</td>
            <td>{{ element.last_location }}</td>
            <td>{{ element.name }}</td>
            <td>{{ element.client }}</td>
            <td>{{ element.last_order_status }}</td>
            <td>
              <DateDisplay :end="element.rent_end" :start="element.rent_start"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div><!-- Normal Data table -->
      <div v-if="changeModalData.closedElements.data.length">
        <div class="d-flex justify-content-between">
          <p class="fs-5" v-html="changeModalData.closedElements.subtitle"/>
        </div>
        <table class="table table-bordered table-hover table-sm text-nowrap">
          <thead class="table-secondary">
          <tr class="table-danger">
            <th>Order</th>
            <th>Unit</th>
            <th>Unit Status</th>
            <th>Location</th>
            <th>Name</th>
            <th>Client</th>
            <th>Order Status</th>
            <th>Dates</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="element of changeModalData.closedElements.data"
              :class="{'table-active table-danger':element.checked}">
            <td>{{ element.order }}</td>
            <td>{{ element.sku }}</td>
            <td>{{ element.last_status }}</td>
            <td>{{ element.last_location }}</td>
            <td>{{ element.name }}</td>
            <td>{{ element.client }}</td>
            <td>{{ element.last_order_status }}</td>
            <td>
              <DateDisplay :end="element.rent_end" :start="element.rent_start"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div><!-- Closed Data table -->
      <div v-if="changeModalData.previousElements.data.length">
        <div class="d-flex justify-content-between">
          <p class="fs-5" v-html="changeModalData.previousElements.subtitle"/>
        </div>
        <table class="table table-bordered table-hover table-sm text-nowrap">
          <thead class="table-secondary">
          <tr class="table-danger">
            <th scope="col">Check</th>
            <th>Booking</th>
            <th>Unit</th>
            <th>Status</th>
            <th>Location</th>
            <th>Order</th>
            <th>Name</th>
            <th>Order Status</th>
            <th>Client</th>
            <th>Dates</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="element of changeModalData.previousElements.data"
              :class="{'table-active table-danger':element.checked}">
            <th class="action-checkbox-column">
              <input :id="element.booking" v-model="element.checked" name="action-select" type="checkbox"
                     @input="toggleNormUnit(element)">
            </th> <!-- Action checkbox column -->
            <td>{{ element.booking }}</td>
            <td>{{ element.sku }}</td>
            <td>{{ element.last_status }}</td>
            <td>{{ element.last_location }}</td>
            <td>{{ element.order }}</td>
            <td>{{ element.name }}</td>
            <td>{{ element.last_order_status }}</td>
            <td>{{ element.client }}</td>
            <td>
              <DateDisplay :end="element.rent_end" :start="element.rent_start"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div><!-- Previous Data table -->
      <div class="form-floating">
        <textarea v-model="changeModalData.note" class="form-control" style="height: 51px"/>
        <label class="form-label">Note</label>
      </div>
    </template>
    <template v-slot:buttons>
      <button v-if="showModalYesButton" class="btn btn-dark" data-bs-dismiss="modal"
              @click="changeUnitField">Yes
      </button>
    </template>
  </ModalPopup>
</template>

<script setup>
import Preview from "@/components/Preview.vue";
import {computed, onMounted, ref, watch} from "vue";
import {LocationService, OperationService, OrderStatusService, UnitService, UnitStatusService} from "@/services";
import {useAppStore} from "@/stores";
import Change from "@/components/Change.vue";
import Table from "@/components/Table.vue";
import ImgLink from "@/components/ImgLink.vue";
import ModalPopup from "@/components/ModalPopup.vue";
import DateDisplay from "@/components/DateDisplay.vue";
import {useRouter} from "vue-router";

const title = "Operation";
const props = defineProps({
  params: {
    type: Object,
    required: false,
    default: {}
  }
})
const params = computed(() => props.params);
const loading = ref(false);
const tableHeaders = [
  {key: null, value: "Booking"},
  {key: null, value: "Operations"},
  {key: null, value: "Order"},
  {key: null, value: "Unit"},
  {key: null, value: "Unit Status"},
  {key: null, value: "Location"},
  {key: null, value: "Storage"},
  {key: null, value: "Name"},
  {key: null, value: "Brand"},
  {key: null, value: "Client"},
  {key: null, value: "Dates"},
  {key: null, value: "Note"},
  {key: null, value: "Rent/Sale"},
  {key: null, value: "Next Booking"},
  {key: null, value: "Returned"},
  {key: null, value: "Blocking Dates"},
  {key: null, value: "Shipping Dates"},
  {key: null, value: "Order Status"},
  {key: null, value: "Order Type"},
  {key: null, value: "Shipping Type"},
  {key: null, value: "Booking Status"},
  {key: null, value: "Remaining Uses"},
  {key: null, value: "Created"},
  {key: null, value: "Updated"},
]
const appStore = ref(useAppStore());
const router = useRouter();
const orderLinesFound = ref([]);
const selectedElements = computed(() => orderLinesFound.value?.filter(e => e.checked).map(e => e.slug) ?? []);
const statuses = ref([]);
const locations = ref([]);
const orderStatuses = ref([]);
const orderTypes = appStore.value.orderTypes;
const deliveryTypes = appStore.value.deliveryTypes;
const bookingStatuses = appStore.value.bookingStatuses;
const searchInput = ref(null);
const changeModalElement = ref();
const changeModalData = ref({
  previousElements: {data: [], subtitle: ''},
  closedElements: {data: [], subtitle: ''},
  data: [],
});
const showModalYesButton = computed(() => Boolean(changeModalData.value?.data.find(el => el.checked)));
const selectedStatus = ref("");
const selectedLocation = ref("");
const selectedOrderStatus = ref("");

onMounted(() => {
  document.title = `${title}`;
  appStore.value.triggerSidebarLoad();
  loadStatuses();
  loadLocations();
  loadOrderStatus();
  changeModalElement.value._element.addEventListener('hidden.bs.modal', () => {
    clearModalData();
  });
  loadData();
});

const clearSelection = () => {
  orderLinesFound.value.forEach(e => e.checked = false);
  selectedStatus.value = "";
  selectedLocation.value = "";
  selectedOrderStatus.value = "";
  router.push({query: {}});
  clearModalData();
  document.querySelectorAll('input[name^="action-toggle"]').forEach((el) => el.checked = false);
}

const clearModalData = () => {
  changeModalData.value = {
    previousElements: {data: [], subtitle: ''},
    closedElements: {data: [], subtitle: ''},
    data: [],
  }
}

const loadData = async () => {
  if (params.value?.search) {
    searchInput.value = params.value.search;
    await searchOrderLine();
  }
}

const searchOrderLine = async () => {
  if (searchInput.value) {
    try {
      loading.value = true;

      let response = await OperationService.searchOrderLine({search: searchInput.value});
      if (response.status === 200) {
        addToOrderLines(response.data);
      } else {
        appStore.value.addToast('Error', 'Error while searching unit', 'danger');
      }
    } catch (error) {
      console.log(error);
      appStore.value.addToast('Error', error, 'danger');
    } finally {
      loading.value = false;
      searchInput.value = "";
      appStore.value.triggerSidebarLoad();
    }
  }
}

const addToOrderLines = (newOrderLines) => {
  for (const orderLine of newOrderLines) {
    orderLine.originalStatus = orderLine.unit_status;
    orderLine.originalLocation = orderLine.unit_location;
  }
  const uniqueOrderLines = [];
  const existingIds = new Set(orderLinesFound.value.map(booking => booking.id));
  for (const newOrderLine of newOrderLines) {
    // if the id is not already in the list, add it if it is, update it
    if (!existingIds.has(newOrderLine.id)) {
      uniqueOrderLines.push(newOrderLine);
    } else {
      const index = orderLinesFound.value.findIndex(booking => booking.id === newOrderLine.id);
      orderLinesFound.value[index] = newOrderLine;
    }
  }
  orderLinesFound.value = [...orderLinesFound.value, ...uniqueOrderLines];
}

const removeOrderLines = () => {
  orderLinesFound.value = orderLinesFound.value.filter((el) => !selectedElements.value.includes(el.slug));
  if (!orderLinesFound.value.length) clearSelection();
}

const clearOrderLines = () => {
  orderLinesFound.value = [];
  clearSelection();
}

const reloadOrderLines = async () => {
  searchInput.value = orderLinesFound.value.map((el) => el.unit.sku).join(',');
  await searchOrderLine();
}

const getSelectedElements = (elements, newStatus, newLocation, newOrderStatus) => {
  const status = statuses.value.find(el => el.name === newStatus?.value) ?? null
  const location = locations.value.find(el => el.name === newLocation?.value) ?? null
  const order_status = orderStatuses.value.find(el => el.name === newOrderStatus?.value) ?? null
  if (newStatus) newStatus.selectedIndex = Array.from(newStatus.options).find(e => e.value === elements[0].unit?.status.name).index
  if (newLocation) newLocation.selectedIndex = Array.from(newLocation.options).find(e => e.value === elements[0].unit?.location.name).index
  if (order_status) newOrderStatus.selectedIndex = Array.from(newOrderStatus.options).find(e => e.value === elements[0].order_status?.name).index
  openChangeModal(elements, status, location, order_status)
}

const openChangeModal = async (
    bookings,
    newStatus = statuses.value.find(el => el.name === selectedStatus.value),
    newLocation = locations.value.find(el => el.name === selectedLocation.value),
    newOrderStatus = orderStatuses.value.find(el => el.name === selectedOrderStatus.value)
) => {
  if (!bookings) {
    if (selectedElements.value.length < 1) {
      appStore.value.addToast('Warning', 'Please select at least one element from the table', 'danger');
      return;
    }
    bookings = orderLinesFound.value.filter((el) => selectedElements.value.includes(el.slug));
  }

  const changedStatuses = []
  const changedLocations = []
  const changedOrderStatuses = []
  bookings = bookings.filter(el => {
    const status = el.unit?.status?.id !== newStatus?.id;
    const location = el.unit?.location?.id !== newLocation?.id;
    const orderStatus = el.order_status?.name !== newOrderStatus?.name;
    if (newLocation) changedLocations.push(location ? newLocation : undefined);
    if (newStatus) changedStatuses.push(status ? newStatus : undefined);
    if (newOrderStatus) changedOrderStatuses.push(orderStatus ? newOrderStatus : undefined);
    return (newLocation && location) || (newStatus && status) || (newOrderStatus && orderStatus);
  });

  if (!bookings.length) {
    appStore.value.addToast('Warning', 'No booking to update', 'danger');
    return;
  }

  newStatus = changedStatuses.some(e => e !== undefined) ? newStatus : undefined;
  newLocation = changedLocations.some(e => e !== undefined) ? newLocation : undefined;
  newOrderStatus = changedOrderStatuses.some(e => e !== undefined) ? newOrderStatus : undefined;

  changeModalData.value.title = 'Multiple Changes';

  let newStatusSubtitle = newStatus ? `Status: <strong>${newStatus?.name}</strong>` : '';
  let newLocationSubtitle = newLocation ? `Location: <strong>${newLocation?.name}</strong>` : '';
  let newOrderStatusSubtitle = newOrderStatus ? `Order Status: <strong>${newOrderStatus?.name}</strong>` : '';
  changeModalData.value.subtitle = `To ${[newStatusSubtitle, newLocationSubtitle, newOrderStatusSubtitle].filter(Boolean).join(' and ')}`;

  if (!newStatus && !newLocation && !newOrderStatus) {
    appStore.value.addToast('Warning', 'Please select a valid status, location u order status', 'danger');
    return;
  }
  let normalElements = [];
  let previousElements = [];
  let closedElements = [];
  bookings.forEach((oLine) => {
    let changeElement = {
      slug: oLine.slug,
      sku: oLine.unit?.sku,
      booking: oLine.is_sale ? null : oLine.id,
      sale: oLine.is_sale ? oLine.id : null,
      order: oLine.order_number,
      name: oLine.name,
      client: oLine.client,
      unit: oLine.unit?.id,
      status: newStatus?.id,
      location: newLocation?.id,
      order_status: newOrderStatus?.id,
      last_status: oLine.unit?.status.name,
      last_location: oLine.unit?.location.name,
      last_order_status: oLine.order_status?.name,
      last_line_status: oLine.status,
      rent_start: oLine.is_sale ? oLine.fulfillment_date : oLine.rent_start,
      rent_end: oLine.is_sale ? null : oLine.rent_end,
      checked: true,
      closed: oLine.is_sale ? (newStatus?.name === 'Vendido') : (newStatus?.name === 'Almacenado'),
      current_booking: oLine.unit?.current_booking?.id,
      is_cancel: oLine?.is_cancel,
      is_return: oLine?.is_return,
    }
    if (isClosed(changeElement)) {
      closedElements.push(changeElement);
    } else {
      normalElements.push(changeElement);
      let current_booking = oLine.unit?.current_booking;
      if ((newStatus || newLocation) && current_booking && oLine.id !== current_booking?.id) {
        let previousBooking = {
          slug: oLine.slug,
          sku: oLine.unit?.sku,
          booking: current_booking?.id,
          order: current_booking?.order_number,
          name: oLine.name,
          client: current_booking?.client,
          unit: oLine.unit?.id,
          last_status: oLine.unit?.status.name,
          last_location: oLine.unit?.location.name,
          last_order_status: current_booking?.order_status?.name,
          last_line_status: current_booking?.status,
          rent_start: current_booking?.rent_start,
          rent_end: current_booking?.rent_end,
          checked: true,
          closed: true,
          is_cancel: current_booking?.is_cancel,
          is_return: current_booking?.is_return,
        }
        if (isClosed(previousBooking)) {
          changeModalData.value.closedElements.subtitle = `Some units have previous open bookings. This change will affect them.<br>`;
          normalElements.find(e => e.booking === changeElement.booking).checked = false
          closedElements.push(previousBooking);
        } else {
          previousElements.push(previousBooking);
        }
      }
    }
  });
  if (normalElements.length) {
    changeModalData.value.data = normalElements.filter((el) => !previousElements.map((el) => el.booking).includes(el.booking));
  }
  if (previousElements.length) {
    changeModalData.value.previousElements.subtitle = `Some units have previous open bookings. Do you want to close them?`;
    changeModalData.value.previousElements.data = previousElements;
  }
  if (closedElements.length) {
    changeModalData.value.closedElements.subtitle += '<strong>Error! These Bookings are not finalized. This change is not allowed.</strong>'
    changeModalData.value.closedElements.data = closedElements;
  }
  changeModalElement.value.show();
}

const isClosed = (e) => {
  return e && e.closed && !(
      ['Finalizado', 'Finalizado con daños', 'Devuelto', 'Cancelado', 'Reserva Cancelada']
          .includes(e.last_order_status) || !e.last_order_status || e.is_cancel || e.is_return ||
      ['cancelado', 'finalizado'].includes(e.order_status));
}

const changeUnitField = async () => {
  if (changeModalData.value?.note) {
    changeModalData.value.data.forEach((el) => el.note = changeModalData.value?.note);
  }
  const final_data = [...changeModalData.value?.previousElements.data, ...changeModalData.value?.data]
  const filtered_data = final_data.filter((el) => el.checked);
  if (!filtered_data.length) {
    appStore.value.addToast('Warning', 'No booking to update', 'danger');
    return;
  }
  try {
    console.log('changing units')
    loading.value = true;
    let responseChange = await UnitService.changeField(filtered_data);
    if (responseChange.status === 200) {
      appStore.value.addToast('Success', "Units updated", 'success');
      clearSelection();
      orderLinesFound.value = orderLinesFound.value.filter(
          (el) => !responseChange.data.map((el) => el.id).includes(el.unit.id));
      searchInput.value = responseChange.data.map((el) => el.sku).join(',');
      appStore.value.addToast('Success', "Reloading data", 'success');
      await searchOrderLine();
    } else {
      appStore.value.addToast('Error', "Error while updating units", 'danger');
    }
  } catch (error) {
    loading.value = true;
    console.log(error);
    appStore.value.addToast('Error', error, 'danger');
  }
}

const togglePrevUnit = (target) => {
  const element = changeModalData.value.data.find((el) => el?.slug === target.slug);
  if (element) {
    const prevBooking = changeModalData.value.previousElements.data.find((el) => el.booking === element?.current_booking);
    if (prevBooking) {
      prevBooking.checked = !target.checked;
    }
    const normBooking = changeModalData.value.data.find((el) => el.booking === element?.current_booking);
    if (normBooking) {
      normBooking.checked = !target.checked;
    }
  }
}

const toggleNormUnit = (target) => {
  const element = changeModalData.value.previousElements.data.find((el) => el?.slug === target.slug);
  if (element) {
    const NormBookings = changeModalData.value.data.filter((el) => (
        el.current_booking === element.booking || el.booking === element.booking));
    if (NormBookings) {
      for (const NormBooking of NormBookings) {
        NormBooking.checked = !target.checked;
      }
    }

  }
}

const loadStatuses = async () => {
  if (statuses.value.length === 0) {
    console.log('loading statuses');
    try {
      const response = await UnitStatusService.list();
      statuses.value = response.data.map((el) => {
        return {
          id: el.id,
          name: el.name,
          to_location: el.to_location,
          to_order_status: el.to_order_status
        }
      });
    } catch (error) {
      console.log(error);
      appStore.value.addToast('Error', error, 'danger');
    }
  }
};

const loadLocations = async () => {
  if (locations.value.length === 0) {
    console.log('loading locations')
    try {
      const response = await LocationService.list();
      locations.value = response.data.map((el) => {
        return {
          id: el.id,
          name: el.name,
          type: el.type
        }
      });
    } catch (error) {
      console.log(error);
      appStore.value.addToast('Error', error, 'danger');
    }
  }
};

const loadOrderStatus = async () => {
  if (orderStatuses.value.length === 0) {
    const response = await OrderStatusService.list();
    orderStatuses.value = response.data;
  }
}

watch(selectedStatus, (newValue) => {
  if (newValue) {
    const status = statuses.value.find(e => e.name === newValue);
    if (status?.to_location) {
      selectedLocation.value = locations.value.find(e => e.id === status.to_location)?.name ?? "";
    } else {
      selectedLocation.value = "";
    }
    if (status?.to_order_status) {
      selectedOrderStatus.value = orderStatuses.value.find(e => e.id === status.to_order_status)?.name ?? "";
    } else {
      selectedOrderStatus.value = "";
    }
  }
});
</script>

<style scoped>
</style>